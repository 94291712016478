import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"fill-height d-flex flex-column dsfr no-hover pa-sm-6",attrs:{"outlined":""}},[_c(VCardTitle,[_c('h3',{staticClass:"fr-h4 mb-0"},[_vm._v(" Mon affiche ")])]),_c(VSpacer),_c(VCardText,{staticClass:"fr-text grey--text text--darken-2"},[_c('p',{staticClass:"publication-detail"},[_vm._v(" Statut "),_c('DsfrBadge',{attrs:{"mode":_vm.isPublished ? 'SUCCESS' : 'INFO',"icon":_vm.isPublished ? 'mdi-circle' : 'mdi-circle-outline'}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.isPublished ? "En ligne" : "Non publiée")+" ")])])],1),_c('p',{staticClass:"publication-detail"},[_vm._v(" Dernière mise à jour "),_c('span',{staticClass:"font-weight-bold fr-text-xs"},[_vm._v(_vm._s(_vm.publicationUpdateDate))])]),_c('p',{staticClass:"publication-detail"},[_vm._v(" Visiteurs (année en cours) "),_c('span',{staticClass:"font-weight-bold fr-text-xs"},[_vm._v(" "+_vm._s(_vm.isPublished && _vm.viewCount > 0 ? _vm.viewCount : "-")+" ")])])]),_c(VSpacer),_c(VCardActions,{staticClass:"flex-wrap flex-lg-nowrap"},[(_vm.isPublished)?_c('p',{staticClass:"mx-2 mb-2"},[_c(VBtn,{staticClass:"px-3",attrs:{"to":{
          name: 'PublicationForm',
          params: { canteenUrlComponent: _vm.$store.getters.getCanteenUrlComponent(_vm.canteen) },
        },"color":"primary","outlined":""}},[_vm._v(" Éditer mon affiche ")])],1):_vm._e(),_c('p',{staticClass:"mx-2 mb-2"},[_c(VBtn,{staticClass:"fr-btn--tertiary px-3",attrs:{"outlined":"","color":"primary","to":{ name: 'CanteenGeneratePoster' }}},[_vm._v(" Générer mon affiche ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }