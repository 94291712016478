import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[_c('BreadcrumbsNav',{attrs:{"links":[{ to: { name: 'ManagementPage' } }]}}),_c('h1',{staticClass:"font-weight-black text-h4 my-6"},[_vm._v("Importer vos données")]),_c('p',[_vm._v(" Vous êtes en mesure d'exporter vos données en format CSV ? Utilisez notre outil d'import pour ajouter vos cantines rapidement ! ")]),_c('p',[_vm._v(" Sinon, utilisez notre "),_c('router-link',{attrs:{"to":{ name: 'NewCanteen' }}},[_vm._v("formulaire pour ajouter une nouvelle cantine")]),_vm._v(" tout en simplicité depuis votre navigateur. ")],1),_c('h2',{staticClass:"mt-6 mb-8"},[_vm._v("Choisissez un des types d'import suivants :")]),_c(VRow,{staticClass:"cta-group pa-2"},_vm._l((_vm.diagnosticTypes),function(type){return _c(VCol,{key:type.key,attrs:{"cols":"12","sm":"4"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"fill-height pa-4 d-flex flex-column hover-transition",attrs:{"elevation":hover ? 6 : 2,"to":{ name: 'DiagnosticImportPage', params: { importUrlSlug: type.urlSlug } }}},[_c(VIcon,{staticClass:"pt-4 pb-1 align-self-center",attrs:{"x-large":_vm.$vuetify.breakpoint.mdAndUp,"color":"primary"}},[_vm._v(" "+_vm._s(type.icon)+" ")]),_c(VCardTitle,[_c('h2',{staticClass:"text-body-2 text-md-h6 font-weight-bold"},[_vm._v(" "+_vm._s(type.title)+" ")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(type.help)+" ")])]),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 pt-0"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)]}}],null,true)})],1)}),1),_c('h2',{staticClass:"mt-10 mb-2"},[_vm._v("Vous gérez une cuisine centrale ?")]),_c('p',{staticClass:"mt-0 mb-6"},[_vm._v("Renseignez vos satellites et vos données d'approvisionnement avec le même fichier")]),_c(VRow,{staticClass:"cta-group pa-2"},[_c(VSpacer),_vm._l((_vm.ccDiagnosticTypes),function(type){return _c(VCol,{key:type.key,attrs:{"cols":"12","sm":"4"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"fill-height pa-4 d-flex flex-column hover-transition",attrs:{"elevation":hover ? 6 : 2,"to":{ name: 'DiagnosticImportPage', params: { importUrlSlug: type.urlSlug } }}},[_c(VIcon,{staticClass:"pt-4 pb-1 align-self-center",attrs:{"x-large":_vm.$vuetify.breakpoint.mdAndUp,"color":"primary"}},[_vm._v(" "+_vm._s(type.icon)+" ")]),_c(VCardTitle,[_c('h2',{staticClass:"text-body-2 text-md-h6 font-weight-bold"},[_vm._v(" "+_vm._s(type.title)+" ")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(type.help)+" ")])]),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 pt-0"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)]}}],null,true)})],1)})],2),_c(VDivider,{staticClass:"mt-12 mb-4",attrs:{"aria-hidden":"true","role":"presentation"}}),_c('HelpForm')],1)}
var staticRenderFns = []

export { render, staticRenderFns }