import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[_c('div',{staticClass:"d-block d-sm-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex flex-column mb-2"},[_c('h1',{staticClass:"fr-text font-weight-bold"},[_vm._v("Mon affiche")]),_c('p',{staticClass:"mb-0 text-uppercase"},[_c('DsfrBadge',{attrs:{"mode":_vm.badge.mode,"icon":_vm.badge.icon}},[_vm._v(" "+_vm._s(_vm.badge.text)+" ")])],1)]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mx-2 px-3 py-2",staticStyle:{"border":"dotted 2px #CCC"}},[_c('p',{staticClass:"mb-0 fr-text-sm font-weight-medium"},[_vm._v(" Personnalisez votre affiche pour communiquer avec vos convives ")])]):_vm._e(),_c('div',[_c(VBtn,{staticClass:"mr-2 mb-4 mb-sm-0",attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"color":"primary","outlined":"","to":{ name: 'CanteenGeneratePoster' }}},[_vm._v(" Télécharger en .pdf ")]),_c(VBtn,{staticClass:"mb-4 mb-sm-0",attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"disabled":!_vm.isPublished,"color":"primary","to":{
          name: 'CanteenPage',
          params: { canteenUrlComponent: _vm.canteenUrlComponent },
        },"target":"_blank","rel":"noopener external","title":"Voir la version en ligne - ouvre une nouvelle fenêtre"}},[_vm._v(" Voir la version en ligne "),_c(VIcon,{staticClass:"ml-1",attrs:{"small":"","color":"white"}},[_vm._v("mdi-open-in-new")])],1)],1)]),(_vm.$vuetify.breakpoint.smAndUp)?_c('ImagesField',{staticClass:"mt-0 mb-4",attrs:{"canteen":_vm.canteen,"end":_vm.imageHeaderLimit}}):_vm._e(),_c('CanteenHeader',{staticClass:"my-6",attrs:{"canteen":_vm.canteen},on:{"logoChanged":function (x) { return (_vm.originalCanteen.logo = x); }}}),_c('CanteenPublication',{attrs:{"canteen":_vm.canteen,"editable":true}}),_c('div',[(_vm.showImagesOverflow)?_c('div',[_c('h3',[_vm._v("Galerie")]),_c('ImagesField',{staticClass:"mt-0 mb-4",attrs:{"canteen":_vm.canteen,"start":_vm.imageHeaderLimit,"end":_vm.additionalImagesMax}})],1):_vm._e(),(_vm.isPublished)?_c('DsfrAccordion',{staticClass:"my-6",attrs:{"items":[{ title: 'Ajouter un aperçu sur votre site' }]},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('AddPublishedCanteenWidget',{attrs:{"canteen":_vm.canteen}})]},proxy:true}],null,false,1216301422)}):_vm._e(),_c(VSheet,{staticClass:"d-flex flex-wrap align-center",attrs:{"rounded":"","color":"grey lighten-4 pa-3 my-6"}},[_c(VForm,{ref:"form",staticClass:"publication-checkbox",on:{"submit":function($event){$event.preventDefault();}}},[_c('PublicationField',{attrs:{"canteen":_vm.canteen},model:{value:(_vm.acceptPublication),callback:function ($$v) {_vm.acceptPublication=$$v},expression:"acceptPublication"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c(VSpacer):_vm._e(),(!_vm.isPublished)?_c(VBtn,{staticClass:"mr-4 align-self-center",attrs:{"x-large":"","outlined":"","color":"primary","to":{
          name: 'DashboardManager',
          params: { canteenUrlComponent: _vm.canteenUrlComponent },
        }}},[_vm._v(" Annuler ")]):_vm._e(),(!_vm.isPublished)?_c(VBtn,{attrs:{"x-large":"","color":"primary"},on:{"click":_vm.publishCanteen}},[_vm._v(" Publier ")]):_c(VBtn,{staticClass:"mr-4",attrs:{"x-large":"","color":"red darken-3","outlined":""},on:{"click":_vm.removeCanteenPublication}},[_vm._v(" Retirer la publication ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }