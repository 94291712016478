import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.visibleSatellites)?_c(VDataTable,{attrs:{"options":_vm.options,"server-items-length":_vm.satelliteCount || 0,"items":_vm.visibleSatellites,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VDialog,{attrs:{"max-width":"800px"},model:{value:(_vm.joinDialog),callback:function ($$v) {_vm.joinDialog=$$v},expression:"joinDialog"}},[_c(VCard,{staticClass:"text-left"},[_c(VCardTitle,[_c('h1',{staticClass:"fr-h5 mb-2"},[_vm._v("Rejoindre l'équipe de « "+_vm._s(_vm.restrictedSatellite.name)+" »")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" Vous n'êtes pas encore un membre de l'équipe de « "+_vm._s(_vm.restrictedSatellite.name)+" » alors vous devez demander l'accès pour pouvoir voir et modifier les données de cette cantine. ")]),_c('DsfrTextarea',{staticClass:"mt-2 body-2",attrs:{"label":"Message","hide-details":"auto","rows":"2"},model:{value:(_vm.messageJoinCanteen),callback:function ($$v) {_vm.messageJoinCanteen=$$v},expression:"messageJoinCanteen"}})],1),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"py-4 pl-6"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.sendMgmtRequest}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-key")]),_vm._v(" Demander l'accès ")],1),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.joinDialog = false}}},[_vm._v(" Annuler ")])],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.userCanView && _vm.includeSatelliteLink)?_c('router-link',{attrs:{"to":_vm.satelliteLink(item)}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.userCanView",fn:function(ref){
var item = ref.item;
return [(!item.userCanView)?_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.requestAccess(item)}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-key")]),_vm._v(" Rejoindre l'équipe "),_c('span',{staticClass:"d-sr-only"},[_vm._v("de "+_vm._s(item.name))])],1):(_vm.satelliteAction && _vm.satelliteAction(item))?_c(VBtn,{attrs:{"outlined":"","color":_vm.satelliteAction(item).color || 'primary'},on:{"click":function($event){_vm.satelliteAction(item).action()}}},[(_vm.satelliteAction(item).icon)?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.satelliteAction(item).icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.satelliteAction(item).text)+" "),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(item.name))])],1):(_vm.satelliteLink(item))?_c(VBtn,{attrs:{"outlined":"","color":"primary","to":_vm.satelliteLink(item)}},[_vm._v(" Mettre à jour "),_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(item.name))])]):_vm._e()]}},{key:"item.publicationStatus",fn:function(ref){
var item = ref.item;
return [(item.publicationStatus === 'draft')?_c('span',[_vm._v("Non publié")]):(item.publicationStatus === 'published')?_c('router-link',{attrs:{"to":{
          name: 'CanteenPage',
          params: { canteenUrlComponent: _vm.$store.getters.getCanteenUrlComponent(item) },
        },"target":"_blank","title":"Publié - ouvre une nouvelle fenêtre"}},[_vm._v(" Publié "),_c('span',{staticClass:"d-sr-only"},[_vm._v("- page pour "+_vm._s(item.name))]),_c(VIcon,{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-open-in-new")])],1):_vm._e()]}},(_vm.allowUnlinking)?{key:"item.unlink",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{staticClass:"text-decoration-underline",attrs:{"plain":""},on:{"click":function($event){return _vm.unlinkConfirmation(item)}}},[_c(VIcon,{staticClass:"mr-1 mb-n1",attrs:{"small":""}},[_vm._v("$close-line")]),_vm._v(" Enlever ")],1)]}}:null,{key:"no-data",fn:function(){return [_vm._v(" Vous n'avez pas renseigné des satellites ")]},proxy:true}],null,true)}):_vm._e(),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.unlinkConfirmationOpen),callback:function ($$v) {_vm.unlinkConfirmationOpen=$$v},expression:"unlinkConfirmationOpen"}},[(_vm.unlinkItem)?_c(VCard,{staticClass:"text-left"},[_c(VCardTitle,[_c('h1',{staticClass:"fr-h5 mb-2"},[_vm._v("Voulez-vous vraiment enlever cette cantine de vos satellites ?")])]),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(" La cantine « "+_vm._s(_vm.unlinkItem.name)+" » ne fera plus parti de celles fournies par votre établissement. ")])]),_c(VDivider,{attrs:{"aria-hidden":"true","role":"presentation"}}),_c(VCardActions,{staticClass:"pa-4"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.unlinkConfirmationOpen = false}}},[_vm._v(" Non, revenir en arrière ")]),_c(VBtn,{attrs:{"outlined":"","color":"red darken-2","text":""},on:{"click":function($event){return _vm.unlinkSatellite(_vm.unlinkItem.id)}}},[_vm._v(" Oui, enlever la cantine ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }