import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[_c('h1',{staticClass:"font-weight-black text-h4 my-4"},[_vm._v("Publier mes satellites")]),_c('div',[(_vm.pubLoading)?_c(VRow,{staticClass:"green--text"},[_c(VCol,{attrs:{"cols":"1","justify-self":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1),_c(VCol,[_c('p',[_vm._v("Publications en cours...")])])],1):(_vm.unpublishedCount)?_c('p',[_vm._v(" "+_vm._s(_vm.publishActionPreamble)+" "),_c('br'),(_vm.satellitesToPublish.length)?_c(VBtn,{staticClass:"primary mt-3",on:{"click":_vm.massPublication}},[(_vm.satellitesToPublish.length > 1)?_c('span',[_vm._v("Publier "+_vm._s(_vm.satellitesToPublish.length)+" satellites")]):_c('span',[_vm._v("Publier la cantine satellite")])]):_vm._e()],1):(_vm.satelliteCount)?_c('p',[_c(VIcon,{attrs:{"size":"30","color":"green"}},[_vm._v(" $checkbox-circle-fill ")]),_vm._v(" Tous vos satellites sont publiés. ")],1):_vm._e()],1),_c('SatelliteTable',{ref:"satelliteTable",staticClass:"mb-4",attrs:{"headers":_vm.satelliteTableHeaders,"includeSatelliteLink":true,"canteen":_vm.canteen,"params":_vm.satelliteTableParams,"satelliteAction":_vm.satelliteAction},on:{"mountedAndFetched":_vm.mountedAndFetched,"paramsChanged":_vm.updateRoute,"satellitesLoaded":_vm.updateSatellitesCounts}}),(!_vm.receivesGuests)?_c('div',[_c('p',[_vm._v(" « "+_vm._s(_vm.originalCanteen.name)+" » est une cuisine centrale sans lieu de consommation. La publication concerne "),_c('b',[_vm._v("uniquement les lieux de restauration recevant des convives.")])]),_c('p',[_vm._v(" Vous pouvez "),_c('router-link',{attrs:{"to":{ name: 'NewCanteen' }}},[_vm._v("ajouter une cantine satellite")]),_vm._v(" ou indiquer que "),_c('router-link',{attrs:{"to":{
          name: 'CanteenModification',
          params: { canteenUrlComponent: _vm.$store.getters.getCanteenUrlComponent(_vm.originalCanteen) },
        }}},[_vm._v(" votre cuisine centrale reçoit aussi des convives sur place. ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }