import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"fill-height d-flex flex-column dsfr no-hover pa-sm-6",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"pb-0"},[_c('h3',{staticClass:"fr-h4 mb-0"},[_vm._v("Mes satellites")])]),(!_vm.satellites.length)?_c(VCardText,{staticClass:"fr-text-xs grey--text text--darken-2 mt-3 pb-0"},[_c('p',{staticClass:"mb-0"},[_vm._v("Ajoutez et publiez les cantines que vous livrez")])]):_vm._e(),(!_vm.satellites.length)?_c(VSpacer):_vm._e(),(_vm.canteen.satelliteCanteensCount)?_c(VCardText,{class:("fr-text-xs mt-3 pb-0 " + (_vm.hasSatelliteInconsistency ? 'dark-orange' : 'grey--text text--darken-2'))},[_c('p',{staticClass:"mb-0 d-flex"},[(_vm.hasSatelliteInconsistency)?_c(VIcon,{staticClass:"mr-1 dark-orange",attrs:{"small":""}},[_vm._v("$alert-line")]):_vm._e(),_vm._v(" "+_vm._s(_vm.satelliteCount)+" sur "+_vm._s(_vm.canteen.satelliteCanteensCount)+" satellites renseignés ")],1)]):_vm._e(),(_vm.satellites.length)?_c(VSpacer):_vm._e(),(_vm.satellites.length)?_c(VCardText,{staticClass:"py-0"},[_c(VDataTable,{class:("dsfr-table grey--table " + (_vm.satellites.length && 'table-preview')),attrs:{"items":_vm.satellites,"headers":_vm.satelliteHeaders,"hide-default-footer":true,"disable-sort":true,"dense":""},scopedSlots:_vm._u([{key:"item.publicationStatus",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-bold px-2 fr-text-xs text-uppercase",class:_vm.isSatellitePublished(item) ? 'green--text text--darken-4' : 'grey--text text--darken-2',staticStyle:{"border-radius":"4px !important"},attrs:{"color":_vm.isSatellitePublished(item) ? 'green lighten-4' : 'grey lighten-2',"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.isSatellitePublished(item) ? "Publiée" : "Non publiée")+" ")])]}}],null,true)})],1):_vm._e(),_c(VSpacer),_c(VCardActions,{staticClass:"flex-wrap"},[_c('p',{staticClass:"mx-2 mb-2"},[_c(VBtn,{attrs:{"to":{
          name: 'SatelliteManagement',
          params: { canteenUrlComponent: _vm.$store.getters.getCanteenUrlComponent(_vm.canteen) },
        },"color":"primary","outlined":!!_vm.satellites.length}},[_vm._v(" "+_vm._s(_vm.satellites.length ? "Modifier" : "Ajouter mes satellites")+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }